<template>
  <div class="card">
    <h2 class="card-header">
      Metricas / Cuentas
      <a-button
        shape="circle"
        icon="sync"
        class="float-right"
        @click="fetch" />
    </h2>
    <div class="card-body">
      <a-table
        row-key="id"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :bordered="false">
        <span slot="delivered" slot-scope="row">
          {{ row.delivered | percent(row.collected) }}%
        </span>
        <span slot="issues" slot-scope="row">
          {{ row.issues | percent(row.collected) }}%
        </span>
        <span slot="lead_time" slot-scope="row">
          {{ row.lead_time | hs }} hs
        </span>
        <span slot="on_time" slot-scope="row">
          {{ row.on_time | percent(row.collected) }}%
        </span>
      </a-table>
    </div>
  </div>
</template>
<script>
import lms from '@/api/lms'
const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Colectados',
    dataIndex: 'collected',
  },
  {
    title: 'Entregados',
    key: 'delivered',
    scopedSlots: { customRender: 'delivered' },
  },
  {
    title: 'Incidencias',
    key: 'issues',
    scopedSlots: { customRender: 'issues' },
  },
  {
    title: 'Lead Time',
    key: 'leadtime',
    scopedSlots: { customRender: 'lead_time' },
  },
  {
    title: 'On Time',
    key: 'ontime',
    scopedSlots: { customRender: 'on_time' },
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      return lms.metrics.getAccounts()
        .then(r => (this.data = r.data))
    },
  },
}
</script>
